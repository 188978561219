import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SoundCloud from "../components/soundcloud"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap";

class SongPost extends Component {
  render() {
    const songpost = this.props.data.nodeSong

    return (
      <>
        <Layout>
          <Container>
            <h1>{songpost.title}</h1>
            <Row>
              <Col className="border border p-4 my-4">
                <SoundCloud
                  soundCloudTrackId={songpost.field_soundcloud_track_id}
                  songTitle={songpost.title}
                />

                <div>
                  Listen: <a href={songpost.field_spotify.uri}>Spotify</a> | <a href={songpost.field_apple_music.uri}>Apple Music</a> | <a href={songpost.field_amazon_music.uri}>Amazon Music</a> | <a href={songpost.field_youtube_music.uri}>YouTube Music</a> | <a href={songpost.field_soundcloud.uri}>SoudCloud</a>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div class="script">{songpost.field_release_date}</div>
                <div dangerouslySetInnerHTML={{ __html: songpost.field_description.processed }} />
              </Col>
              <Col md={6} className="border p-4">
                <h2>Lyrics</h2>
                <div dangerouslySetInnerHTML={{ __html: songpost.field_lyrics.processed }} />
              </Col>
            </Row>
          </Container>
        </Layout>
      </>
    )
  }
}

SongPost.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default SongPost

export const pageQuery = graphql`
  query($id: String!) {
    nodeSong(id: { eq: $id }) {
      title
      field_release_date(formatString: "MMMM DD, YYYY")
      field_lyrics {
        processed
      }
      field_description {
        processed
      }
      field_apple_music {
        uri
      }
      field_soundcloud {
        uri
      }
      field_spotify {
        uri
      }
      field_amazon_music {
        uri
      }
      field_youtube_music {
        uri
      }
      field_soundcloud_track_id
    }
  }
`