import React from "react"

const SoundCloud = ({ soundCloudTrackId, songTitle }) => (
    <div className="soundcloud">
        <iframe 
            title={songTitle}
            width="100%" 
            height="166" 
            scrolling="no" 
            frameborder="no" 
            allow="autoplay" 
            src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + soundCloudTrackId + "&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"}
             />
    </div>
)

export default SoundCloud